import React from 'react'
import Layout from 'components/layout'

// connect to the backend
import Amplify from 'aws-amplify'
import config from '../aws-exports'
Amplify.configure(config)


const NotFoundPage = () => (
    <Layout>
      <p>
        This is not the page you are looking for
      </p>
    </Layout>
)

export default NotFoundPage

